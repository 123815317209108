import { Link } from "react-router-dom";
import Top100 from "./components/Top100";
import Cta from "./Cta";
import Footer from "./Footer";
import Header from "./Header";

function Prices() {
  return (
    <>
      <Header />
      <div className="fixer">
        <div className="hero">
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          <div className="hero-text">
            <h1>Preturi</h1>
            <h2>Preturi pentru orice buzunar!</h2>

            <div className="hero-button">
            <Link to="/contact" className="btn cta-button">
            Contact!
              </Link>
            </div>
          </div>
          <div className="hero-img">
            <div className="overlay"></div>
            <img src="images/bg.jpg" alt="hero background" />
          </div>
        </div>
        <section className="prices">
          <div className="container">
            <div className="table">
              <div className="tr">
                <div className="tc th">
                  <p>Serviciu</p>
                </div>
                <div className="tc th">
                  <p>Pret</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Masaj general</p>
                </div>
                <div className="tc">
                  <p>150 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Masaj anticelulitic</p>
                </div>
                <div className="tc">
                  <p>150 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Masaj spate</p>
                </div>
                <div className="tc">
                  <p>100 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Reflexoterapie</p>
                </div>
                <div className="tc">
                  <p>100 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Masaj facial <span className="new-product">(!NOU)</span></p>
                </div>
                <div className="tc">
                  <p>100 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Masaj general + facial <span className="new-product">(!NOU)</span></p>
                </div>
                <div className="tc">
                  <p>200 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>MASAJ SPORTIV</p>
                </div>
                <div className="tc">
                  <p>150 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>MASAJ CERVICAL</p>
                </div>
                <div className="tc">
                  <p>50 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>MASAJ MEMBRE INFERIOARE</p>
                </div>
                <div className="tc">
                  <p>100 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ FACIAL 6 SEDINTE <span className="new-product">(!NOU)</span></p>
                </div>
                <div className="tc">
                  <p>500 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ FACIAL 12 SEDINTE <span className="new-product">(!NOU)</span></p>
                </div>
                <div className="tc">
                  <p>1000 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ GENERAL + MASAJ FACIAL 6 SEDINTE <span className="new-product">(!NOU)</span></p>
                </div>
                <div className="tc">
                  <p>1000 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ GENERAL + MASAJ FACIAL 12 SEDINTE <span className="new-product">(!NOU)</span></p>
                </div>
                <div className="tc">
                  <p>2000 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ GENERAL 6 + REFLEXOTERAPIE 6 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>1250 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ GENERAL 12 + REFLEXOTERAPIE 12 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>2500 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ GENERAL 6 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>750 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ GENERAL 12 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>1500 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT REFLEXOTERAPIE 6 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>500 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT REFLEXOTERAPIE 12 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>1000 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ SPATE 6 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>500 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ SPATE 12 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>1000 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ABONAMENT MASAJ SPATE 6 + REFLEXOTERAPIE 6 SEDINTE</p>
                </div>
                <div className="tc">
                  <p>1000 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>APLICARE BANDA KINESIOLOGICA</p>
                </div>
                <div className="tc">
                  <p>50 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>CONSULTATIE NUTRITIE</p>
                </div>
                <div className="tc">
                  <p>400 RON</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>PSIHOTERAPIE</p>
                </div>
                <div className="tc">
                  <p>250 RON</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Cta title="Salut, te-am convins si esti hotarat sa te faci bine?" subtitle="Contacteaza-ma!" />
        <Footer />
      </div>
      <Top100 />
    </>
  )
}

export default Prices