import Top100 from "./components/Top100"
import Cta from "./Cta"
import Footer from "./Footer"
import Header from "./Header"

function About() {
  return (
    <>
      <Header />
      <div className="fixer">
        <div className="hero">
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          <div className="hero-text">
            <h1>Echipa MassageMania</h1>
            <h2>Cu drag, pentru voi!</h2>
          </div>
          <div className="hero-img">
            <div className="overlay"></div>
            <img src="images/bg.jpg" alt="hero background" />
          </div>
        </div>
        <section className="about test-about">
          <div className="container-fluid main">
            <div className="row mb-5">
              <div className="col-12">
                <div className="container">
                  <h3>Marian Eremia</h3>
                  <p>In anul 2016 am decis sa infiintez un mic cabinet specializat doar pe masaj din dorinta de a oferii servicii de calitate si personalizate.
                    Prin toate saloanele se promoveaza agresiv aparate minune, dar tehnica manuala lasa mult de dorit.
                    De aceea am decis ca "MassageMania" sa ofere doar masaj. Oferta noastra cuprinde de la Reflexoterapie pana la masaj Sportiv.
                  </p>
                  <p>Din cele mai vechi timpuri masajul a fost folosit ca forma de tratament si relaxare. De aceea folosim masajul ca forma de tratament in afectiuni
                    musculare, posturale si nu numai. Ca adjuvant pentru afectiuni interne sau cauzate de stres aplicam Reflexoterapia.</p>
                  <p>Va asteptam cu drag sa testati serviciile noastre si sa va bucurati de efectele miraculoase ale masajului.</p>
                </div>
              </div>
            </div>
            <div className="row my-2 my-lg-10">
              <div className="col-12 text-center col-sm-10 offset-sm-1 col-lg-5 col-xl-4 offset-xl-2">
                <img src="images/marian.jpg" alt="science" />
              </div>
              <div className="col-12 col-sm-10 offset-sm-1 col-lg-5 offset-lg-0 mt-lg-0 col-xl-4">
                <h3>Experienta profesionala</h3>
                <p>2001 - 2006 - Maseur Club "Life" - fitness & beauty center</p>
                <p>2007 - 2014 - Maseur - Federatia Romana de Atletism - Lot National si Lot Olimpic - sectia "Aruncari de greutate"</p>
                <p>2014 - 2016 - Maseur si coordonator clinica "MedySportLine"</p>
                <p>2016 - Prezent - Massage Mania by Marian Eremia</p>
              </div>
            </div>
            <div className="row mb-5 mt-5">
              <div className="col-12">
                <div className="container">
                  <h3>Ana Safta</h3>
                  <p>Intotdeauna mi-a placut sa fac bine si am ales sa-mi fac o cariera din asta. Masajul mi-a venit ca o manusa si m-am adaptat foarte usor.
                    Sub indrumarea lui Marian si a altor terapeuti profesionisti am reusit sa capat foarte multa experienta intr-un timp scurt. <br />
                    Practic cu pasiune iar rezultatele nu intarzie sa apara.
                    Sunt o fire sociabila, prietenoasa si mereu gata sa ajut.
                  </p>
                  <p>Mi-ar placea sa te ajut si abia astept sa lucram impreuna.</p>
                </div>
              </div>
            </div>
            <div className="row my-2 my-lg-10">
              <div className="col-12 text-center col-sm-10 offset-sm-1 col-lg-5 col-xl-4 offset-xl-2">
                <img src="images/ana_safta.jpg" alt="science" />
              </div>
              <div className="col-12 col-sm-10 offset-sm-1 col-lg-5 offset-lg-0 mt-lg-0 col-xl-4">
                <h3>Experienta profesionala</h3>
                <p>2014 - 2017 - Maseur / fizioterapeut la clinica "MedySportLine"</p>
                <p>2017 - 2019 - Concediu de crestere a copilului</p>
                <p>2019 - Prezent - Massage Mania by Marian Eremia</p>
              </div>
            </div>
          </div>
        </section>
        <Cta title="Acum ca ma cunosti mai bine, vrei sa ne si vedem?" subtitle="Contacteaza-ma!" />
        <Footer />
      </div>
      <Top100 />
    </>
  )
}

export default About