import { Link } from "react-router-dom";
import { useState } from "react";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const hide = () => setIsOpen(false);

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src="/images/logo_web.png" alt="massagemania logo" />
        </Link>
      </div>
      <nav>
        <ul className="menu">
          <li>
            <Link to="/">
              Acasa
            </Link>
          </li>
          <li>
            <Link to="/prices">
              Preturi
            </Link>
          </li>
          <li>
            <Link to="/about">
              Echipa
            </Link>
          </li>
          <li>
            <Link to="/nutrigenetica">
              Nutrigenetica
            </Link>
          </li>
          <li>
            <Link to="/testare-genetica">
              Testare genetica
            </Link>
          </li>
          <li>
            <Link to="/contact">
              Contact
            </Link>
          </li>
        </ul>

        <div className={`mobile-menu ${isOpen ? 'is-open' : ''}`}>
          <button onClick={toggleMenu} className="open-main-nav">
            <span className={`burger ${isOpen ? 'is-open' : ''}`}></span>
          </button>
          <nav className={`main-nav ${isOpen ? 'is-open' : ''}`}>
            <ul>
              <li>
                <Link to="/" onClick={hide}>
                  Acasa
                </Link>
              </li>
              <li>
                <Link to="/prices" onClick={hide}>
                  Preturi
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={hide}>
                  Echipa
                </Link>
              </li>
              <li>
                <Link to="/nutrigenetica" onClick={hide}>
                  Nutrigenetica
                </Link>
              </li>
              <li>
                <Link to="/testare-genetica" onClick={hide}>
                  Testare genetica
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={hide}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
    </header>
  )
}

export default Header
