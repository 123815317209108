import Top100 from "./components/Top100"
import Cta from "./Cta"
import Footer from "./Footer"
import Header from "./Header"

function Nutrigenetica() {
  return (
    <>
      <Header />
      <div className="fixer">
        <div className="hero">
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          <div className="hero-text">
            <h1>Nutrigenetica</h1>
            <h2>Sanatatea vine din interior!</h2>
          </div>
          <div className="hero-img">
            <div className="overlay"></div>
            <img src="images/nutritie.jpg" alt="hero background" />
          </div>
        </div>
        <section className="about eat-about">
          <div className="container main">
            <div className="row my-2 my-lg-10">
              <div className="col-12 text-center col-sm-12 col-lg-6">
                <img src="images/nutrigenetica.svg" alt="science" />
              </div>
              <div className="col-12 col-sm-12 col-lg-6">
                <h3>Nutrigenetica</h3>
                <p>Stiinta nutritiei are ca scop analizarea modului in care nutrientii si alte componente ale dietei influenteaza starea de sanatate si boala de-a lungul vietii. Aceasta se bazeaza pe cunostintele de baza: caracteristicile nutrientilor, biologia si biochimia metabolizarii acestora, caile de semnalizare si rolul lor in homeostazia organismului. Studiaza erorile de metabolism prezente inca de la nastere, chiar in termeni de simpla susceptibilitate si incearca sa inteleaga interactiunea gene-nutrient. Pe de alta parte, dezvoltarea rapida a biologiei moleculare a dat nastere la o noua disciplina, nutritia moleculara, bazata pe nutrigenomica si nutrigenetica.</p>
                <p>Nutrigenetica studiaza modul in care genotipul unui individ poate sa influenteze dieta, in timp ce nutrigenomica raspunde la intrebarea opusa: cum anume dieta poate sa influenteze transcriptia genetica, expresia proteica si metabolismul.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-4">
                <p>In practica, pe de o parte genele sunt importante in determinarea unei functii, iar pe de alta, nutritia este determinanta in modificarea gradului de expresie genetica si deci in conditionarea lecturii informatiei continuta in ADN.</p>
                <p>In nutrigenomica, nutrientii sunt vazuti ca semnale care spun organismului cum sa se comporte. Celulele organismului raspund la aceste semnale modificând expresia genelor. Practic avem genotipul pe de o parte si pe de alta o interactiune activa si pasiva cu factorii ambientali (stil de viata, nutritie, stress, obiceiuri etc.) si care duc in final la exprimarea caracteristicilor proprii = fenotip.</p>
                <p>Am vazut ca variabilitatea genetica cea mai comuna este reprezentata de SNP in care are loc substitutia unei singure baze in secventa de ADN. O parte din aceste SNP sunt importante in nutritie. Interactiunea dintre genom si nutrienti duce la variaţii ale expresiei genelor implicate in diferite activitati functionale, metabolice ale organsimului.</p>
                <p>Substantele chimice din nutrienti pot influenta expresia genica direct sau indirect.</p>
                <p>La nivel celular, nutrietii pot:</p>
                <ul>
                  <li>
                    <p>sa actioneze ca liganzi pentru receptorii factorilor de transcriptie (de exemplu acizi grasi precum palmitic oleic, linolenic, arahidonic, linoleic, eicosanoizii sunt liganzi pentru clasele PPAR deci se comporta ca senzori pentru acizii grasi. Pe de alta parte si vitamina A poate lega direct receptorii nucleari si sa influenteze expresia genica);</p>
                  </li>
                  <li>
                    <p>sa fie metabolizati de caile metabolice primare sau secundare si prin urmare sa altereze concentratia substratului;</p>
                  </li>
                  <li>
                    <p>sa influenteze pozitiv sau negativ caile de transductie ale semnalelor celulare.</p>
                  </li>
                </ul>
                <p>Principalii agenti prin intermediul carora nutrientii influenteaza expresia genica sunt reprezentati de factorii de transcriptie (sequence-specific DNA-binding factor).</p>
                <p>Factorul de transcriptie este o proteina care leaga ADN-ul intr-o portiune specifica a promotorului sau enhancerului de unde poate controla transcriptia. Pot fi activati sau dezactivati selectiv de alte proteine de obicei ca pas final al transductiei.  Printre cei mai importanti “senzori ai nutrientilor” conteaza familia receptorilor nucleari ai factorilor de transcriptie cu circa 48 de molecule in contextul genomului uman. in interirorul acestei familii, numerosi receptori leaga direct fie nutrientii, fie metabolitii lor.</p>
                <p>Dezvoltarea nutrigeneticii si nutrigenomicii, medicinei nutritionale si moleculare a dus la o optimizare a productiei de alimente. in viitorul apropiat credem ca va exista o banca de date ADN-nutrient si va fi posibila elaborarea de diete in care alimentul nu va mai fi privit doar ca mâncare pur si simplu, evaluat dupa proprietati organoleptice si calitative, dar va fi protagonistul preventiei anumitor patologii devenind o specie de medicament.</p>
                <p>Interactiunea dintre genomica si stiinta nutritiei a inceput sa clarifice complexitatea raspunsurilor genomice la nutrientii din dieta, oferind posibilitatea cresterii eficientei interventiilor nutritionale, fie la nivel individual cât si de populatie.</p>
              </div>
            </div>
          </div>
        </section>
        <Cta title="Vrei sa-ti imbunatatesti calitatea vietii si prin dieta?" subtitle="Contacteaza-ma!" />
        <Footer />
      </div>
      <Top100 />
    </>
  )
}

export default Nutrigenetica