function Top100() {
  return(
   <div className="top-c-100">
     <a href="https://www.top100ofromania.eu/company/1412786/massagemania-by-marian-eremia" rel="noreferrer" target="_blank">
      <img src="/images/top-100.png" alt="top100 romania" />
    </a>
   </div>
  )
}

export default Top100