import { Link } from "react-router-dom";

function Cta({ title, subtitle }) {
  return (
    <section className="cta">
      <div className="cta-left">
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
      <div className="cta-right">
        <Link to="/contact"  className="btn cta-button">
          Contact
        </Link>
      </div>
    </section>
  )
}

export default Cta
