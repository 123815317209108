import '../styles/App.scss'
import 'react-image-lightbox/style.css';
import Home from './react/Home'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import About from './react/About'
import Prices from './react/Prices'
import Nutrigenetica from './react/Nutrigenetica'
import Testare from './react/Testare'
import Contact from './react/Contact'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/prices" element={<Prices />}></Route>
      <Route path="/nutrigenetica" element={<Nutrigenetica />}></Route>
      <Route path="/testare-genetica" element={<Testare />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
    </Routes>
  )
}

export default App
