import { useState } from "react";
import Lightbox from 'react-image-lightbox';
import { Link } from "react-router-dom";
import Top100 from "./components/Top100";
import Cta from "./Cta";
import Footer from "./Footer";
import Header from "./Header";

const carouselImages = [
  '/images/cabinet/8.jpg',
  '/images/cabinet/1.jpg',
  '/images/cabinet/2.jpg',
  '/images/cabinet/3.jpg',
  '/images/cabinet/4.jpg',
  '/images/cabinet/5.jpg',
  '/images/cabinet/6.jpg',
  '/images/cabinet/7.jpg',
];

const massageImages = [
  '/images/relaxare.jpg',
  '/images/anticelulitic.jpg',
  '/images/reflexo.jpg',
  '/images/terapeutic.jpg',
  '/images/sportiv.jpg',
  '/images/personalizat.jpg',
  '/images/nutritie.jpg',
  '/images/bg-test.jpg'
]

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecondary, setIsOpenSecondary] = useState(false);
  const toggleLightbox = () => setIsOpen(!isOpen);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [massagePhotoIndex, setMassagePhotoIndex] = useState(0);

  return (
    <>
      <Header />
      <div className="fixer">
        <div className="hero">
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          <div className="hero-text">
            <h1>MassageMania</h1>
            <h2>Oaza ta de liniste si pace!</h2>

            <div className="hero-button">
              <Link to="/contact" className="btn cta-button">
                Contact!
              </Link>
            </div>
          </div>
          <div className="hero-img">
            <div className="overlay"></div>
            <img src="/images/bg.jpg" alt="hero background" />
          </div>
        </div>
        <section className="about">
          <div className="container-fluid main">
            <div className="row my-2 my-lg-10">
              <div className="col-12 text-center col-sm-10 offset-sm-1 col-lg-5 col-xl-4 offset-xl-2">
                <img src="/images/meditation.svg" alt="meditation" />
              </div>
              <div className="col-12 col-sm-10 offset-sm-1 col-lg-5 offset-lg-0 mt-lg-0 col-xl-4">
                <h3>Masaj de relaxare, anticelulitic, terapeutic si reflexoterapie</h3>
                <p>Pentru ca ne dorim sa oferim clientilor nostri o experienta completa, venim in intampinarea
                  acestora si cu servicii de nutritie. De asemenea, avem o gama variata de servicii de testare
                  genetica, remarcandu-ne prin cel mai mic pret practicat din tara. </p>
                <p>De asemenea, aplicam cu succes ca forma de tratament complementar reflexoterapia in diverse afectiuni ale
                  organismului, recomandand în acelasi timp neintreruperea tratamentului alopat prescris de medicul curant.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="services">
          <ul className="services-list">
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/relaxare.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Masaj de relaxare</h4>
                  <p>Masajul de relaxare este acel masaj care ne rupe de stresul cotidian si de tensiunile musculare capatate
                    la birou sau la locul de munca.
                    Ajuta la eliberarea de endorfine si serotonina, avand un impact puternic si la nivel psihic, nu doar
                    fizic.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/anticelulitic.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Masaj anticelulitic</h4>
                  <p>Scopul masajului anticelulitic este acela de a remedia aspectul tesutului adipos. Principalul beneficiu
                    al masajului anticelulitic este
                    imbunatatirea circulatiei sanguine, ceea ce conduce la reducerea si eliminarea depunerilor adipoase in
                    timp.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/reflexo.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Reflexoterapie</h4>
                  <p>Istoria si vechimea reflexologiei nu pot fi stabilite cu precizie, ele pierzandu-se in trecutul fabulos
                    al marilor civilizatii ale omenirii, cum ar fi civilizatia chineza si cea egipteana. In China antica
                    reflexoterapia facea parte integrata din originalul sistem al acupuncturii si presopuncturii.</p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/terapeutic.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Masaj terapeutic</h4>
                  <p>Masajul terapeutic este una dintre cele mai vechi proceduri de tratament impotriva durerii si afectiunii
                    de coloana sau a aparatului locomotor. Acesta se aplica pentru decompresarea vertebrala, blocaje
                    musculare, atrofii musculare si multe altele.</p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/sportiv.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Masaj sportiv</h4>
                  <p>Masajul sportiv prin definitie se adreseaza in general sportivilor de performanta. Ajuta la imbunatatirea performantei. Prin acest masaj se
                    urmareste relaxarea musculaturii, o reoxigenare a acesteia si
                    eliberarea de reziduri din tesut.</p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/personalizat.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Masaj personalizat</h4>
                  <p>Adaptam masajul in functie de nevoile fiecarui client dupa o discutie si o examinare prealabila. Astfel reusim sa rezolvam problemele si in acelasi
                    timp sa il si relaxam oferind cel mai eficient mod de tratare individuala.</p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/nutritie.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Nutrigenetica</h4>
                  <p>Nutrigenetica studiaza modul in care genotipul unui individ poate sa influenteze dieta, in timp ce
                    nutrigenomica raspunde la intrebarea: cum anume dieta poate sa influenteze transcriptia genetica,
                    expresia proteica si metabolismul.</p>
                </div>
              </div>
            </li>
            <li>
              <div className="front">
                <div className="image-holder">
                  <a href="#" onClick={() => setIsOpenSecondary(true)}><img src="/images/bg-test.jpg" alt="" /></a>
                </div>
                <div className="text-holder">
                  <h4>Testare genetica</h4>
                  <p>Testul genetic se efectuează o singură dată în viață și poate contribui la reducerea sau prevenirea
                    apariției unor patologii, ajutând astfel la menținerea unei stări de sănătate optimă pe termen lung.</p>
                </div>
              </div>
            </li>
          </ul>

          {isOpenSecondary && (
            <Lightbox
              mainSrc={massageImages[massagePhotoIndex]}
              nextSrc={massageImages[(massagePhotoIndex + 1) % massageImages.length]}
              prevSrc={massageImages[(massagePhotoIndex + massageImages.length - 1) % massageImages.length]}
              onCloseRequest={() => setIsOpenSecondary(false)}
              onMovePrevRequest={() => setMassagePhotoIndex((massagePhotoIndex + massageImages.length - 1) % massageImages.length)}
              onMoveNextRequest={() => setMassagePhotoIndex((massagePhotoIndex + 1) % massageImages.length)}
            />
          )}
        </section>
        <section className="about">
          <div className="container-fluid main">
            <div className="row my-2 my-lg-10">
              <div className="col-12 text-center col-sm-10 offset-sm-1 col-lg-5 col-xl-4 offset-xl-2">
                <img src="/images/history.svg" alt="meditation" />
              </div>
              <div className="col-12 col-sm-10 offset-sm-1 col-lg-5 offset-lg-0 mt-lg-0 col-xl-4">
                <h3>Scurt istoric al masajului</h3>
                <p>Ca ramura a medicinii orientale, reflexologia este stiinta zonelor reflexogene ale organismului situate pe suprafata corpului, in general la distanta fata de organul pe care il reprezinta.</p>
                <p>In China zilelor noastre reflexoterapia este utilizata ca si forma de tratament complementar in spitale, acolo unde tratamentul alopat nu da rezultate.</p>
                <p>Este o forma de tratament neinvaziva ce echilibreaza corpul. Trateaza de la insomnii la boli incurabile.</p>
                <p>Masajul este o arta care s-a dezvoltat si a persistat de-a lungul timpului inca din antichitate.</p>
                <p>Cele mai vechi referiri la masaj se regasesc in zona Asiei si se pare ca masajul era cunoscut ca cea mai buna tehnica de vindecare. De asemenea, in Grecia Antica, Hippocrates, tatal medicinei, facand referire la calitatile si cunostintele medicilor considera foarte important ca acestia sa cunoasca tehnici terapeutice de masaj.</p>
                <p>In perioada romana, Galen, considerat unul dintre cei mai buni instorici medicali si-a inceput cariera ca doctor al Gladiatorilor. In acest timp a pregatit oameni care sa aplice diferite tehnici de masaj pentru diferite afectiuni.</p>
                <p>Masajul ofera un spectru mare de beneficii: oxigenarea musculaturii, relaxarea musculara, remedierea si armonizarea trupului.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="photo-carousel">
          <a href="#" onClick={toggleLightbox} className="view-carousel"></a>
          <span><img className="d-block img-fluid" src="/images/cabinet/2.jpg" alt="Second slide" /></span>
          <span><img className="d-block img-fluid" src="/images/cabinet/1.jpg" alt="Third slide" /></span>
          <span><img className="d-block img-fluid" src="/images/cabinet/6.jpg" alt="Fourth" /></span>
          <span><img className="d-block img-fluid" src="/images/cabinet/7.jpg" alt="Fifth slide" /></span>

          {isOpen && (
            <Lightbox
              mainSrc={carouselImages[photoIndex]}
              nextSrc={carouselImages[(photoIndex + 1) % carouselImages.length]}
              prevSrc={carouselImages[(photoIndex + carouselImages.length - 1) % carouselImages.length]}
              onCloseRequest={toggleLightbox}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + carouselImages.length - 1) % carouselImages.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % carouselImages.length)}
            />
          )}
        </section>
        <Cta title="Hey, vrei sa te simti mai bine si mai sanatos?" subtitle="Contacteaza-ma!" />
        <Footer />
      </div>
      <Top100 />
    </>
  )
}

export default Home;