import Top100 from "./components/Top100"
import Cta from "./Cta"
import Footer from "./Footer"
import Header from "./Header"

function Testare() {
  return (
    <>
      <Header />
      <div className="fixer">
        <div className="hero">
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          <div className="hero-text">
            <h1>Testare genetica</h1>
            <h2>Decopera-te!</h2>
          </div>
          <div className="hero-img">
            <div className="overlay"></div>
            <img src="images/bg-test.jpg" alt="hero background" />
          </div>
        </div>
        <section className="about test-about">
          <div className="container-fluid main">
            <div className="row my-2 my-lg-10">
              <div className="col-12 text-center col-sm-10 offset-sm-1 col-lg-5 col-xl-4 offset-xl-2">
                <img src="images/science.svg" alt="science" />
              </div>
              <div className="col-12 col-sm-10 offset-sm-1 col-lg-5 offset-lg-0 mt-lg-0 col-xl-4">
                <h3>Testele genetice si importanta lor</h3>
                <p>Astazi este foarte clar si acceptat ca riscul de aparitie a unei boli depinde de interactiunea intre individ, mediu si susceptibilitatea personala.</p>
                <p>Este posibil sa testam persoane asimptomatice pentru a identifica riscul genetic si in felul acesta, in caz de risc elevat sa luam masurile preventive necesare inainte de aparitia simptomelor. in acelasi timp, indivizi pe care ii suspectam ca fiind afectati de o patologie ereditara, pot fi testati pentru confirmarea acesteia.</p>
                <p>Interesul pentru componenta genetica a susceptiblitatii la boli complexe a devenit foarte important in medicina moderna. Au fost puse in evidenta rolurile unor mutatii comune dar care asociate intre ele sau combinate cu anumite componente ambientale specifice, pot creste semnificativ riscul de a dezvolta patologii difuze.</p>
                <p>Este important sa subliniem faptul ca aceste teste sunt de susceptibilitate si ele nu prezic aparitia unei boli, de aceea trebuiesc evaluate foarte atent de catre specialistul in genetica in colaborare cu medicul curant.</p>
                <p>O mare importanta o are interpretarea statistica a unui rezultat. De exemplu, risc crescut de 30% in cazul unei patologii rare reprezinta un numar mic, in timp ce 30% in cazul unei patologii foarte difuze reprezinta un numar mare.</p>
                <p>Testele genetice reprezinta un instrument stiintific de mare valoare in medicina preventiva.</p>
                <p>Atentie, testul genetic nu ne spune cand ne vom imbolnavi, dar ne furnizeaza infomatii pretioase asupra susceptiblitatii personale spre anumite patologii putand astfel sa actionam in timp util.</p>
                <p>Prin intermediul testarii genetuce putem afla daca suntem ereditar predispusi la cancer, Alzheimer, Parkinson, autism si multe alte patologii care trezesc sentimente de spaima si ingrijorare. </p>
                <p>In alegerea testului genetic veti fi sfatuit de specialistii nostri.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="prices genetic-prices">
          <div className="container">
            <div className="table">
              <div className="tr">
                <div className="tc th">
                  <p>Denumire test</p>
                </div>
                <div className="tc th">
                  <p>Ce analizeaza</p>
                </div>
                <div className="tc th">
                  <p>Pret</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>NUTRIGENE FULL NUTRITION</p>
                </div>
                <div className="tc">
                  <p>Analizeaza 35 GENE corelate cu metabolismul carbohidratilor, predispozitia genetica la ingrasare, rezistenta insulinica, dislipidemii. Mai analizeaza:</p>
                  <p>• capacitatea de detoxifiere a organismului <br />
                    • stresul oxidativ si capacitate antioxidanta <br />
                    • inflamatia bazala <br />
                    • metabolismul vit B si homocisteina, risc de infarct
                    si accident vascular <br />
                    • metabolismul alcoolului <br />
                    • vitamina D <br />
                    • metabolismul cafeinei <br />
                    • sensibilitatea la sare <br />
                    • predispozitia la hipertensiune <br />
                    • intoleranta la lactoza <br />
                    • intoleranta la gluten
                  </p>
                </div>
                <div className="tc">
                  <p>500 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>METABOLIC CHECK DNA</p>
                </div>
                <div className="tc">
                  <p>Analizeaza variantele genetice care altereaza metabolismul si duc la cresterea in greutate</p>
                  <p>1. Sensibilitatea la dieta <br />
                    2. Metabolismul insulinei <br />
                    3. Sensibilitatea la grasimi <br />
                    4. Colesterolul si profilul lipidic <br />
                    5. Metabolismul leptinei
                  </p>
                </div>
                <div className="tc">
                  <p>500 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ANTIAGING CHECK DNA</p>
                </div>
                <div className="tc">
                  <p>Analizeaza predispozitiile genetice care influenteaza imbatranirea precoce si reaparea deficitara ADN
                    care predispune la cancer sporadic</p>
                  <p>1. Detoxifierea <br />
                    2. Metilarea ADN si metabolismul homocisteinei <br />
                    3. Inflammagingul si raspunsul inflamator <br />
                    4. Repararea ADN <br />
                    5. Glicarea <br />
                    6. Stres oxidativ si capacitate antioxidanta
                  </p>
                </div>
                <div className="tc">
                  <p>500 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>WELLNESS CHECK DNA</p>
                </div>
                <div className="tc">
                  <p>Un test complet pentru a cunoaste predispozitiile genetice care regleaza metabolismul nutrientilor,
                    procesul de imbatranire ideal pentru a defini masurile de preventie personalizate. Analizeaza:</p>
                  <p>1. Sensilbilitatea la sare si hipertensiunea <br />
                    2. Detoxifierea <br />
                    3. Stresul oxidativ si capacitatea antioxidanta <br />
                    4. Intoleranta la lactoza <br />
                    5. Intoleranta la gluten <br />
                    6. Sensibilitatea la alcool <br />
                    7. Sensibilitatea la cafeina <br />
                    8. Metilarea ADN si metabolismul homocisteinei <br />
                    9. Inflammaging si raspunsul inflamator <br />
                    10. Colesterolulsiprofilullipidic <br />
                    11. Vitamina D si sanatatea osului
                  </p>
                </div>
                <div className="tc">
                  <p>600 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>TEST PENTRU CELULITA</p>
                </div>
                <div className="tc">
                  <p>Analizeaza variantele genetice corelate cu celulita. <br />
                    Aici exista si creme speciala linia genetica pentru celulita evident nu sunt incluse in pretul testului si suplimente pentru celulita</p>
                </div>
                <div className="tc">
                  <p>400 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>SKIN ANTIAGING</p>
                </div>
                <div className="tc">
                  <p>Stabileste daca prezentati un risc ridicat, mediu sau scăzut în următoarele aspecte ale imbatranirii pielii: Fermitate+ Elasticitate.</p>
                  <p>Testul va spune dacă aveți un dezechilibru al genelor responsabile pentru sintetizare precum și ruperea colagenului și determina dacă sunteți predispusi genetic la imbatranire prematura.</p>
                  <p>Ridare (A.G.E.) – Capacitatea organismului de a produce colagen precum si rata cu care se degradeaza( turnover) sunt 2 aspecte esentiale in aparitia ridurilor. Reactia Maillard sau glicarea proteinelor, este un proces prin care zaharul este captat de proteine, carora le degradeaza structura. Colagenul si elastina, esentiale pentru frumusetea pielii, sunt doua dintre proteinele cele mai afectate de zahar. Ridarea pielii este consecinta scaderii acestor proteine in stratul profund al pielii (dermul). La indivizii care consuma zahar, carbohidrati sau grasimi in exces, acest proces de imbatranire devine foarte accelerat In organismul uman exista mai multe tipuri de colagen (variind de la tipul I pana la tipul VI), gasit in diferite structuri.</p>
                </div>
                <div className="tc">
                  <p>500 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>NEW BORN SCREENING</p>
                </div>
                <div className="tc">
                  <p>Un test genetic pentru nou nascuti care analizeaza predispozitia la:</p>
                  <p>1. surditate indusa de medicamente <br />
                    2. intoleranta la lactoza <br />
                    3. intoleranta la gluten <br />
                    4. hematocromatoza tip 1 <br />
                    5. carenta de AAT( alfa 1 antitripsina) <br />
                    6. galactosemia <br />
                    7. intoleranta la fructoza <br />
                    8. deficit congenital de sucraza-izo
                  </p>
                </div>
                <div className="tc">
                  <p>560 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>ONCONEXT RISK</p>
                </div>
                <div className="tc">
                  <p>Analizeaza 83 de gene corelate cu riscul de cancer (diverse forme de cancer). <br />
                    Tehnica de analiza NGS cu secventiatori Illumina (cea mai avansata tehnica de secventiere).
                  </p>
                </div>
                <div className="tc">
                  <p>1800 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>FARMACOGENETICA</p>
                  <p>RASPUNSUL LA CHIMIOTERAPICE</p>
                </div>
                <div className="tc">
                  <p>RASPUNDE LA INTREBAREA: CARE CHIMIOTERAPICA ESTE CEA MAI BUNA PT MINE</p>
                  <p>Recomandat pentru alegerea tratamentului in cancer</p>
                </div>
                <div className="tc">
                  <p>600 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>FARMACOGENOMICA COMPLET</p>
                </div>
                <div className="tc">
                  <p>Care medicament vi se potriveste?</p>
                </div>
                <div className="tc">
                  <p>1300 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>Genescreening</p>
                </div>
                <div className="tc">
                  <p>Pentru partnerii care se gandesc sa aiba copii.<br />
                    550 gene asociate cu peste 700 de boli genetice ereditare Se recomanda sa fie facut de ambii parinti.</p>
                </div>
                <div className="tc">
                  <p>1800 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>BabyNext</p>
                </div>
                <div className="tc">
                  <p>Test genetic care analizeaza mutatii corelate cu peste 200 boli si metabolismul a peste 30 de medicamente. Excelent pentru noul nascut.</p>
                  <p>E un test foarte bun pentru copii, poate fi facut si cand are copilul 1 luna.</p>
                </div>
                <div className="tc">
                  <p>1300 EUR</p>
                </div>
              </div>
              <div className="tr">
                <div className="tc">
                  <p>EURFIT</p>
                </div>
                <div className="tc">
                  <p>Acest test analizeaza genele corelate cu performanta sportiva printre care: ACE, ACTN3, PPARD, gene corelate cu structura musculara, inflamatia, metabolismul, riscul de tendinite, VO2 maxim, compozitia corporala si Cardiofitnessul.</p>
                </div>
                <div className="tc">
                  <p>500 EUR</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Cta title="Te-ai hotarat sa faci o testare?" subtitle="Contacteaza-ma!" />
        <Footer />
      </div>
      <Top100 />
    </>
  )
}

export default Testare