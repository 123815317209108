import Top100 from "./components/Top100"
import Footer from "./Footer"
import Header from "./Header"

function Contact() {
  return (
    <>
      <Header />
      <div className="fixer">
        <section className="contact">
          <div className="contact-map">
            <iframe title="location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2846.237242252838!2d26.05070640268437!3d44.489805425109175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b20204dfc789d5%3A0xa9a676ecbce095a0!2sMassageMania+by+Marian+Eremia!5e0!3m2!1sen!2sro!4v1554644104311!5m2!1sen!2sro"
              width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
          </div>
          <div className="contact-info">
            <div className="contact-photo">
              <img src="images/cabinet/7.jpg" alt="" />
            </div>
            <div className="contact-details">
              <h3>Email: <span>contact@massagemania.ro</span></h3>
              <h3>Marian Eremia (terapeut): <span>0724 027 323</span><span>0726 577 779</span></h3>
              <h3>Ana Safta (terapeut): <span>0799 468 317</span></h3>
              <h3>Adresa: <span>Strada Calcarului nr. 2, București 013372</span></h3>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      <Top100 />
    </>
  )
}

export default Contact